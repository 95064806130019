/* MAIN */
html {
  /*background: image-url('fundo2.jpg') no-repeat fixed center center;*/
  @include cover;
  position: relative;
  min-height: 100%;
}
  
body {
  /* Margin bottom by footer height */
  //background-color: white;
  padding-bottom: 12em;
  padding-top: 56px;
}

.container-pills {
  margin-top: 7px;
}

.fa {
  font-weight: 100 !important;
}

span#floppy {
  display: none;
}

ul.list-unstyled {
  li {
    line-height: 1.7;
    font-size: 0.875rem;
    a {
      color: #337ab7;
    }
  }
}

.card {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-header {
  padding-bottom: 0.5rem !important;
}

/* FOOTER */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 6em;
  /* height: 60px; */
  line-height: 60px;
  color: $cinza-escuro;
  background-color: $cinza-claro;
  text-align: center;
}


/* FIELDSET */

fieldset.fsStyle {
  font-family: Verdana, Arial, sans-serif;
  font-size: small;
  font-weight: normal;
  border: none;
  padding: 15px;
  margin: 1% 0;
  background-color: $branco-fumaca;
  @include radius(15px);
}

legend.legendStyle {
  font-size: 90%;
  color: #888888;
  background-color: transparent;
  font-weight: bold;
}

/* CONTENT EDITABLE */

[contenteditable]:hover:after {
  content: 'editar';
  font-style: italic;
  font-size: 12px;
  font-family: sans-serif;
  color: #ccc;
}

// [contenteditable]:hover, [contenteditable]:focus {
//   background: #FFFFD3;
// }

[contenteditable]:focus:after {
  content: '';
}

/* HEADER REPORTS */

#ceua-logo img {
  float: left;
}

#usp-logo img {
  float: right;
}

label.mandatory::after {
  content:" *"
}