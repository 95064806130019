/* PROJECT */

#documentation {
  background-color: #FFFFCC;
  padding: 1%;
  margin-bottom: 10px;
}

#usp-logo img {
  float:right;
}

.historic {
  background-color: $azul-celeste;
}

#modal-avaliar {
  width: 900px;
  margin-left: -450px; // half of the width
}

#species thead tr th{
  text-align: center;
  vertical-align: middle;
  background-color: #FFFFCC;
}

#members thead tr th{
  text-align: center;
  vertical-align: middle;
  background-color: #FFFFCC;
}

.fields {
  margin-bottom: 20px;
}

.protocolo {
  background-color: $cinza-claro;
  padding: 2%;
}

table#report thead tr th{
  background-color: $azul-perola;
  h2{
    color: white;
  }
}

.table-colorida {
  thead {
    background-color: $cinza-claro;
    color: black;
  }
  tr td {
    background-color: $branco-fumaca;
  }  
}

.project-title {
  cursor: pointer;
}