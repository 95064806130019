/* MIXINS */
@mixin shadow($color, $x, $y, $radius) {
  -moz-box-shadow:    $color $x $y $radius;
  -webkit-box-shadow: $color $x $y $radius;
  box-shadow:         $color $x $y $radius;
}

@mixin cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin radius ($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}